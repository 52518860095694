import React, { useState } from 'react';
import translate from '../../../../core/translate'; 

import { Modal } from 'react-bootstrap';

import Select from 'react-select'; 

import Translater from './../../../../components/shared/translater';

import FormActionsRow from '../../../../components/shared/form-actions-row';

import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";

import './filters.scss';
import Moment from 'react-moment';

 
const Filters = ({ filters, devices, sites, onFiltersChange }) => {  
    const [selectedDeviceId, setSelectedDeviceId] = useState(filters.selectedDeviceId);
    const [selectedSiteId, setSelectedSiteId] = useState(filters.selectedSiteId);
    const [startDate, setStartDate] = useState(filters.startDate || null);
    const [endDate, setEndDate] = useState(filters.endDate || null);
    const [internalStartDate, setInternalStartDate] = useState(filters.startDate || null);
    const [internalEndDate, setInternalEndDate] = useState(filters.endDate || null);
    const [includeInvalidRecords, setIncludeInvalidRecords] = useState(filters.includeInvalidRecords);
    const [onlyWithUserComments, setOnlyWithUserComments] = useState(filters.onlyWithUserComments || false);
    const [onlyWithSystemComments, setOnlyWithSystemComments] = useState(filters.onlyWithSystemComments || false);
    const [dataSources, setDataSources] = useState(filters.dataSources || []);
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
    
    const onValueChanged = (e, setValue) => { 
        let { name, value, checked } = (e && e.target) || { name: 'dataSources' };

        if (name === 'dataSources'){
            value = (e && e.map(e => e.name)) || [];
        } 
        else if (name === 'startDate'){
            value = internalStartDate;
        }
        else if (name === 'endDate'){
            value = internalEndDate;
        }
        else if (name === 'includeInvalidRecords' || name === 'onlyWithUserComments' || name === 'onlyWithSystemComments'){
            value = checked;
        }

        setValue(value);
        const newFilters = {
            ...{ selectedSiteId, selectedDeviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments, dataSources },
            [name]: value
        };
        onFiltersChange(newFilters, name !== 'dataSources');
    }

    const dataSourcesOptions = [
        { 
            name: 'transparencyArduino', 
            label: translate("historyPage.filters.transparencyArduino"), 
            value: 1                
        }, 
        { 
            name: 'transparencyAnalysisService', 
            label: translate("historyPage.filters.transparencyAnalysisService"), 
            value: 2
        }, 
        { 
            name: 'transparencyAnalysisServiceLive', 
            label: translate("historyPage.filters.transparencyAnalysisServiceLive"), 
            value: 3
        },
        { 
            name: 'graffiti', 
            label: translate("historyPage.filters.graffiti"), 
            value: 4
        },
        { 
            name: 'transparencyCSharpLive', 
            label: translate("historyPage.filters.transparencyCSharpLive"), 
            value: 5
        }
    ];
    
    const selectedDataSourcesOptions = dataSourcesOptions.filter(item => dataSources.some(d => d === item.name));

    return (
        <div className="filters">
            <Modal
                show={isFiltersModalOpen}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setIsFiltersModalOpen(false)}> 
                <Modal.Body>
                    <form> 
                        <div className="form-group"> 
                            <label htmlFor="history-site-id">{ translate("historyPage.filters.pleaseChooseSite") }</label>
                            <select  
                                id="history-site-id"
                                className="form-control mb-2"
                                name="selectedSiteId"
                                value={selectedSiteId}
                                onChange={(e) => onValueChanged(e, setSelectedSiteId) }>
                                <option value=""></option>
                                {
                                    sites && sites.map(item => {
                                        const devicesSerialNumbers = (item.devices && item.devices.map(item => item.serialNumber)) || []; 
                                        const siteTitle = `${item.fullLocation} | ${(devicesSerialNumbers.join(', ')) || 'no devices'}`;  
                                        return <option key={item.id} value={item.id}>{siteTitle}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="form-group"> 
                            <label htmlFor="history-device-id">{ translate("historyPage.filters.pleaseChooseDevice") }</label>
                            <select  
                                id="history-device-id"
                                className="form-control history-device-id mb-2"
                                name="selectedDeviceId"
                                value={selectedDeviceId}                            
                                onChange={(e) => onValueChanged(e, setSelectedDeviceId) }>
                                <option value=""></option>
                                {
                                    devices && devices.map(item => {                                    
                                        return <option key={item.id} value={item.id}>{item.serialNumber}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label>{ translate("historyPage.filters.date") }</label> 

                            <div className="d-flex flex-row justify-content-start"> 
                                <div className="input-group  mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><Translater text={"historyPage.filters.startDate"} />:</div>
                                    </div>
                                    
                                    <DatePicker
                                        selected={internalStartDate}
                                        selectsStart
                                        startDate={internalStartDate}
                                        endDate={internalEndDate} 
                                        onChange={(e) => setInternalStartDate(e)}                                        
                                        onCalendarClose={(e) => onValueChanged({target:{name:'startDate'}}, setStartDate)}
                                        timeFormat="HH:mm"
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        locale={de}
                                        todayButton={"Heute"} 
                                        showTimeSelect
                                        timeCaption="Zeit"
                                        timeIntervals={15}
                                        className="form-control date-picker"
                                        name="startDate"
                                        autoComplete="false"
                                    />
                                </div>
                                <div className="input-group  mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><Translater text={"historyPage.filters.endDate"} />:</div>
                                </div>
                                
                                <DatePicker
                                    selected={internalEndDate}
                                    selectsEnd
                                    startDate={internalStartDate}
                                    endDate={internalEndDate} 
                                    onChange={(e) => setInternalEndDate(e)}                                    
                                    onCalendarClose={(e) => onValueChanged({ target: {name:'endDate'}}, setEndDate)}
                                    minDate={internalStartDate}
                                    timeFormat="HH:mm"
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    locale={de}
                                    todayButton={"Heute"} 
                                    showTimeSelect
                                    timeCaption="Zeit"
                                    timeIntervals={15}
                                    className="form-control date-picker"
                                    name="endDate"
                                    autoComplete="false"
                                />
                            </div>
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label>{ translate("historyPage.filters.pleaseChooseDataSources") }</label> 
                            <div className="data-sources-list  mb-2"> 
                                <Select 
                                    placeholder={ translate("historyPage.filters.pleaseChooseDataSources") }
                                    noOptionsMessage = { () => { return translate("historyPage.filters.noOptions") }}
                                    closeMenuOnSelect={true} 
                                    defaultValue={selectedDataSourcesOptions}
                                    isMulti
                                    options={dataSourcesOptions}  
                                    onChange={(e) => onValueChanged(e, setDataSources) }
                                    name="dataSources"
                                />
                            </div> 
                        </div>

                        <div className="form-group form-check">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id="includeInvalidRecords" 
                                name="includeInvalidRecords"
                                checked={includeInvalidRecords}  
                                onChange={(e) => onValueChanged(e, setIncludeInvalidRecords) }
                            />
                            <label className="form-check-label" htmlFor="includeInvalidRecords">
                                <Translater text={"historyPage.filters.includeInvalidRecords"} />
                            </label> 
                        </div> 

                        <div className="form-group form-check">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id="onlyWithUserComments" 
                                name="onlyWithUserComments"
                                checked={onlyWithUserComments}  
                                onChange={(e) => onValueChanged(e, setOnlyWithUserComments) }
                            />
                            <label className="form-check-label" htmlFor="onlyWithUserComments">
                                <Translater text={"historyPage.filters.onlyWithUserComments"} />
                            </label>
                        </div> 

                        <div className="form-group form-check">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id="onlyWithSystemComments" 
                                name="onlyWithSystemComments"
                                checked={onlyWithSystemComments}
                                onChange={(e) => onValueChanged(e, setOnlyWithSystemComments) }
                            />
                            <label className="form-check-label" htmlFor="onlyWithSystemComments">
                                <Translater text={"historyPage.filters.onlyWithSystemComments"} />
                            </label>
                        </div> 
                    </form>
                </Modal.Body> 
                <Modal.Footer>
                    <button className="btn btn-outline-secondary mb-2" onClick={(e) => { e.preventDefault(); setIsFiltersModalOpen(false) } }>                         
                        <Translater text="historyPage.filters.close" />
                    </button>
                </Modal.Footer>
            </Modal>
            
            <form>
                <div className="form-row align-items-center">
                    <div className="col-12 col-md-3">
                        <select  
                            className="form-control mb-2"
                            name="selectedSiteId"
                            value={selectedSiteId}                            
                            onChange={(e) => onValueChanged(e, setSelectedSiteId) }>
                            <option value="">{ translate("historyPage.filters.pleaseChooseSite") }</option>
                            {
                                sites && sites.map(item => {
                                    const devicesSerialNumbers = (item.devices && item.devices.map(item => item.serialNumber)) || []; 
                                    const siteTitle = `${item.fullLocation} | ${(devicesSerialNumbers.join(', ')) || 'no devices'}`;  
                                    return <option key={item.id} value={item.id}>{siteTitle}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className="col-12 col-md-3">                       
                        <select  
                            id="history-device-id"
                            className="form-control history-device-id mb-2"
                            name="selectedDeviceId"
                            value={selectedDeviceId}                            
                            onChange={(e) => onValueChanged(e, setSelectedDeviceId) }>
                            <option value="">{ translate("historyPage.filters.pleaseChooseDevice") }</option>
                            {
                                devices && devices.map(item => {                                    
                                    return <option key={item.id} value={item.id}>{item.serialNumber}</option>
                                })
                            }
                        </select> 
                    </div>

                    {
                        internalStartDate && 
                        <div className="chip mb-2 mr-1">
                            <div className="chip__title">
                                <Translater text={"historyPage.filters.startDate"} />:
                            </div>
                            <Moment format="DD.MM.YYYY HH:mm">{ internalStartDate }</Moment>
                        </div>
                    }
 
                    {
                        internalEndDate && 
                        <div className="chip mb-2 mr-1">
                            <div className="chip__title">
                                <Translater text={"historyPage.filters.endDate"} />:
                            </div>
                            <Moment format="DD.MM.YYYY HH:mm">{ internalEndDate }</Moment>
                        </div>
                    }

                    {
                        dataSources && dataSources.length > 0 &&
                        <div className="chip mb-2 mr-1">
                            <div className="chip__title">
                                <Translater text={"historyPage.filters.dataSources"} />:
                            </div>
                            {
                                dataSourcesOptions.filter(item => dataSources.some(d => d === item.name)).map(item => item.label).join(', ')
                            }
                        </div>
                    }

                    {
                        includeInvalidRecords &&
                        <div className="chip mb-2 mr-1 d-flex align-items-center">   
                            <div className="align-self-center">
                                <Translater text={"historyPage.filters.includeInvalidRecords"} />
                            </div>
                        </div> 
                    }

                    {
                        onlyWithUserComments &&
                        <div className="chip mb-2 mr-1 d-flex align-items-center">   
                            <div className="align-self-center">
                                <Translater text={"historyPage.filters.onlyWithUserComments"} />
                            </div>
                        </div>
                    }

                    {
                        onlyWithSystemComments &&
                        <div className="chip mb-2 mr-1 d-flex align-items-center">   
                            <div className="align-self-center">
                                <Translater text={"historyPage.filters.onlyWithSystemComments"} />
                            </div>
                        </div>
                    }

                    <div className="col-auto">
                        <button className="btn btn-outline-primary mb-2" onClick={(e) => { e.preventDefault(); setIsFiltersModalOpen(true) } }> 
                            <i className="fas fa-filter mr-2"></i>
                            <Translater text="historyPage.filters.moreFilters" />
                        </button>
                    </div> 
                </div>
            </form> 
        </div>
    ); 
}

export default Filters;

