import { registerLang } from 'react-easy-i18n';

registerLang('de', {
  applicationConfigurationEditor: {
    emailServiceSubtitle: 'E-Mail',
    emailServiceSmtpServer: 'SMTP-Server',
    emailServiceSmtpPort: 'Port (25)',
    emailServiceSmtpUsername: 'Benutzername',
    emailServiceSmtpPassword: 'Passwort',
    emailServiceSmtpUseSsl: 'SSL',
    monitoringSubtitle: 'Monitoring',
    monitoringNotificationRecipients: 'Empfänger von Alarm-Benachrichtigungen',
    monitoringMissedMeasurementsThreshold:
      'Schwellwert verpasste Messungen (%)',
    monitoringInvalidMeasurementsThreshold:
      'Schwellwert ungültige Messungen (%)',
    validationSubtitle: 'Validierung',
    validationFilesFolderPath:
      'Verzeichnis für Testdaten (Dateisystem auf dem Server)',
    endpointsSubtitle: 'Service-Endpunkte',
    endpointsReceiverServices: 'Receiver WebService',
    endpointsTrafficServices: 'Traffi WebService',
    endpointsSuitService: 'SIUT Service',
    endpointsAnalysisService: 'AnalysisService',
    globalSubtitle: 'Global', 
    globalCompanyInfo: 'Unternehmensangaben' 
  },
  applicationConfigurationPage: { title: 'Systemkonfiguration' },
  companiesPage: {
    title: 'Verkehrsunternehmen',
    table: { title: 'Name', edit: 'Bearbeiten' },
    add: 'Verkehrsunternehmen hinzufügen'
  },
  companyAddPage: { title: 'Verkehrsunternehmen hinzufügen' },
  companyEditPage: { title: 'Verkehrsunternehmen bearbeiten' },
  companyEditor: { title: 'Name', logo: 'Logo' },
  dashboardPage: {
    activeAlerts: 'Probleme',
    title: 'Dashboard',
    status: 'Status',
    lastDataReceivedDate: 'Letzte Messung',
    track: 'Gleis',
    noAlerts: 'Keine Probleme',
    noDevice: 'Kein Gerät',
    weatherForecast: 'Wetter',
    sunPower: 'Sonne'
  },
  departuresPage: {
    title: 'Abfahrt',
    table: { date: 'Datum', line: 'Linie' },
    filters: { site: 'Installationsort', startDate: 'Von', endDate: 'Bis' }
  },
  deviceAddPage: { title: 'Neues Kondor-Gerät' },
  deviceDetailsPage: { title: 'Kondor-Gerät' },
  deviceEditPage: { title: 'Kondor-Gerät' },
  deviceEditor: {
    serialNumber: 'Seriennummer',
    siut: 'SIUT-Gerät (Ziel)',
    hardwareVersion: 'Hardware-Version',
    firmwareVersion: 'Firmware-Version',
    isOnMonitoring: 'Monitoring',
    monitoringVoltageInternalThreshold:
      'Monitoring: Schwellwert interne Spannung (V)',
    monitoringVoltageExternalThreshold:
      'Monitoring: Schwellwert externe Spannung (V)',
    company: 'Verkehrsunternehmen',
    site: 'Installationsort',
    powerSupplyType: 'Stromversorgung'
  }, 
  deviceIpAddressesPage: {
    title: 'IP-Adressen',  
    table: {
        ipAddress: 'IP-Adresse',  
        startDate: 'Von',  
        endDate: 'Bis',  
        count: 'Anzahl'  
    }
  },
  deviceLogsPage: {
    title: 'Gerätenachrichten',
    table: { createdDate: 'Zeitpunkt der Nachricht', message: 'Nachricht' }
  },
  deviceReport1Page: {
    title: 'Device Report 1',
    download: 'Herunterladen als CSV',
    table: { dateTime: 'Erfassung', avg: 'Durchschnittlich', max: 'Maximum' }
  },
  deviceTelemetriesPage: {
    title: 'Geräte-Telemetrie',
    download: 'Herunterladen als CSV',
    table: {
      dateTime: 'Serverzeit',
      deviceDateTime: 'Gerätezeit',
      voltageInternal: 'Akkuspannung (intern) in V',
      voltageExternal: 'Akkuspannung (extern) in V',
      chargeCurrent: 'Ladestrom in mA',
      transmissionSpeed: 'Transmission speed in Kb/s',
      ipAddress: 'IP-Adresse'  
    }
  },
  devicesPage: {
    title: 'Kondor-Gerät',
    table: {
      serialNumber: 'Seriennummer', 
      hardwareVersion: 'Hardware-Version',
      firmwareVersion: 'Firmware-Version',
      company: 'Verkehrsunternehmen',
      site: 'Installationsort',
      setSite: 'Installationsort festlegen',  
    },
    add: 'Hinzufügen'
  },
  devicePage: {
    title: 'Kondor-Geräte',
    tabs: {
      details: 'Details',  
      telemetry: 'Geräte-Telemetrie',
      report1: 'Übersicht Besetzungshistorie',
      logs: 'Gerätenachrichten',
      ipAddresses: 'IP-Adressen',
      edit: 'Bearbeiten',
      measurements: 'Protokoll'
    },
    edit: 'Bearbeiten',
    delete: 'Löschen',
    deleteConfirm: 'Löschen?'
  },  
  deviceDetailsPage: {
    id: 'Id',
    serialNumber: 'Seriennummer',
    siut: 'SIUT-Gerät (Ziel)',
    hardwareVersion: 'Hardware-Version',
    firmwareVersion: 'Firmware-Version',
    isOnMonitoring: 'Is on monitoring',
    monitoringVoltageInternalThreshold: 'Interne Spannung (V)',
    monitoringVoltageExternalThreshold: 'Externe Spannung (V)',
    company: 'Verkehrsunternehmen', 
    site: 'Installationsort',
    powerSupplyType: 'Stromversorgung'
  }, 
  firmwareVersionAddPage: { title: 'Firmware-Version hinzufügen' },
  firmwareVersionEditPage: { title: 'Firmware-Version bearbeiten' },
  firmwareVersionEditor: {
    name: 'Name',
    hash: 'Hash',
    description: 'Beschreibung'
  },
  firmwareVersionsPage: {
    title: 'Firmware-Versionen',
    table: {
      name: 'Name',
      description: 'Beschreibung',
      hash: 'Hash',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      deleteConfirm: 'Löschen?',
      confirm: 'Bestätigen',
      confirmConfirm: 'Sind Sie sicher?'
    },
    add: 'Hinzufügen'
  },
  fullnessConfigurationPage: {
    title: 'Farbeinstellungen',
    staticLowerThreshold: 'Static Lower Threshold',
    staticUpperThreshold: 'Static Upper Threshold',
    dynamicLowerThreshold: 'Dynamic Lower Threshold',
    dynamicUpperThreshold: 'Dynamic Upper Threshold',
    isDynamicCalculationEnabled: 'Is Dynamic Calculation Enabled',
    explanation1:
      '1.) Wenn alle Werte <strong> geringer als (oder gleich wie) </strong>  der <strong>Dynamic Lower Threshold</strong> sind, dann wird der größte Wert Orange und die übrigen Grün dargestellt. Falls alle Werte gleich sind, werde alle Grün dargestellt. <br/>Beispiel: <br/>Dynamic Lower Threshold = 0.3 =>',
    explanation2:
      '2.) Wenn alle Werte <strong>mindestens so groß</strong> wie der <strong>Dynamic Upper Threshold</strong> sind, dann wird der größte Wert rot, und die übrigen Orange dargestellt. Bei Wertegleichheit sind alle Wagons rot. <br/>  Beispiel: <br/>Dynamic Lower Threshold = 0.7 =>',
    explanation3:
      '3.) Andernfalls werden Werte <strong>kleiner</strong> als der <strong>Static Lower Threshold</strong> grün, Werte <strong>größer</strong> als der <strong>Static Upper Threshold</strong> rot, und alle übrigen orange dargestellt. <br/>Beispiel: <br/>Static Lower Threshold = 0.65,  Static Upper Threshold = 0.8 =>'
  },
  hardwareVersionAddPage: { title: 'Hardware-Version hinzufügen' },
  hardwareVersionEditPage: { title: 'Hardware-Version bearbeiten' },
  hardwareVersionEditor: { name: 'Name', description: 'Beschreibung' },
  hardwareVersionsPage: {
    title: 'Hardware-Versionen',
    table: { name: 'Name', description: 'Beschreibung', edit: 'Bearbeiten' },
    add: 'Hinzufügen'
  },
  historyPage: {
    title: 'Protokoll',
    table: {
      dateTime: 'Erfassung',
      deviceDateTime: 'Gerätezeit',
      fullness: 'Besetzung',
      rawData: 'Rohdaten',
      expand: 'Erweitern',
      trainType: 'Baureihe',
      line: 'Linie',
      comment: 'Kommentar'
    },
    filters: {
      pleaseChooseSite: 'Auswahl Installationsort...',
      pleaseChooseDevice: 'Auswahl Kondor-Gerät...',
      pleaseChooseDataSources: 'Auswahl Datenquelle Ergebnis',
      transparencyArduino: 'Transparency Arduino',
      transparencyAnalysisService: 'Transparency Analysis Service',
      transparencyAnalysisServiceLive: 'Transparency Analysis Service Live',
      graffiti: 'Graffiti',
      transparencyCSharpLive: 'Transparency C# Live',
      noOptions: 'keine Auswahl',
      startDate: 'Von',
      endDate: 'Bis',
      includeInvalidRecords: 'Fehlmessungen anzeigen', 
      onlyWithUserComments: 'Nur mit Benutzerkommentaren', 
      onlyWithSystemComments: 'Nur mit Systemkommentaren',
      moreFilters: 'Weitere Filter ...',
      close: 'Schließen', 
      dataSources: 'Datenquelle',
      date: 'Datum'
    },
    graphic: { wagons: 'Unterer Sensor', fullness: 'Oberer Sensor' },
    commentModal: { title: 'Kommentar', comment: 'Kommentar' },
    downloadTrainInfos: 'Herunterladen',
    addComment: 'Kommentar',
    editComment: 'Kommentar bearbeiten',
    copyRawData: 'Rohdaten in Zwischenablage kopieren',
    copied: 'OK',
    wasDeleted: 'Datensatz gelöscht', 
    delete: 'Löschen', 
    deleteConfirm: 'Löschen?'
  },
  loginPage: {
    loginText: 'Bitte anmelden',
    username: 'Benutzername',
    password: 'Kennwort',
    login: 'Anmelden',
    loggining: 'Anmelden...',
    messages: { invalidUsernameOrPassword: 'Benutzername oder Kennwort falsch' }
  },
  logsPage: {
    title: 'Logfile',
    pleaseChooseDate: 'Auswahl Datum...',
    dateTime: 'Zeit',
    level: 'Stufe',
    application: 'App',
    requestId: 'RequestId',
    message: 'Nachricht'
  },
  monitoringAlertsPage: {
    title: 'Monitoring-Protokoll',
    table: {
      type: 'Alarm Typ',
      device: 'Kondor-Geräte',
      date: 'Datum',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      voltageExternal: 'Spannung (extern)',
      voltageInternal: 'Spannung (intern)',
      expectedMeasurementsCount: 'erwartete Messungen',
      receivedMeasurementsCount: 'empfangene Messungen',
      missedMeasurementsCount: 'verpasste Messungen',
      invalidMeasurementsCount: 'ungültige Messungen',
      solvedDate: 'Problem behoben',
      comment: 'Kommentar',
      description: 'Beschreibung',
      solved: 'Behoben',
      addComment: 'Kommentar hinzufügen',
      showMoreComments: 'Mehr...', 
      commentsLeft: 'weitere Kommentare', 
      showAllComments: 'Alle anzeigen'  
    },
    filters: {
      onlyActive: 'Nur aktuelle',
      pleaseChooseDevice: 'Auswahl Kondor-Gerät...',
      pleaseChooseMonitoringAlertType: 'Auswahl Alarmtyp'
    },
    commentModal: {
      deactivateTitle: 'Deaktivieren',
      addCommentTitle: 'Kommentar hinzufügen',
      comment: 'Kommentar',
      deactivateSameAlerts: 'Alle Alarme dieses Typs deaktivieren' 
    },
    monitoringIsRunning: 'Monitoring läuft',
    runMonitoring: 'Monitoring Sofortabfrage',
    monitoringRunSuccess: 'Monitoring Sofortabfrage erfolgt',
    confirmDeactivateAll: 'Sind Sie sicher?', 
    deactivateAll: 'Alle deaktivieren',  
    deactivateAllByDeviceId: 'Alle Alarme für das ausgewählte Gerät deaktivieren', 
    deactivateAllSuccess: ' Monitoring-Alarme deaktiviert',  
  },
  monitoringPage: {
    title: 'Monitoring',
    isAnalysisServiceAvailable: 'AnalysisService verfügbar',
    table: {
      device: 'Kondor-Gerät',
      lastDataReceivedDate: 'Empfang letzte Messung',
      batteryLevel: 'Akku',
      voltage: 'Spannung bei der zuletzt empfangenen Messung',
      voltageExternal: 'Spannung (extern)',
      voltageInternal: 'Spannung (intern)',
      measurements: 'Messungen',
      expectedMeasurements: 'Erwartet',
      receivedMeasurements: 'Empfangen',
      missedMeasurements: 'Verpasst',
      invalidMeasurements: 'Ungültig'
    }
  },
  notAuthorizedPage: {
    title: 'Error 401: not authorized',
    description: 'Keine Berechtigung'
  },
  report1Page: { title: ' ' },
  serverStatusPage: { title: 'Server status' },
  shared: {
    mainMenu: {
      dashboard: 'Dashboard',
      history: 'Protokoll',
      fullnessConfigurations: 'Farbeinstellungen',
      devices: 'Gerät',
      kondorDevices: 'Kondor-Gerät',
      siuts: 'SIUT',
      logs: 'Logfile',
      reports: 'Ansicht',
      reportOne: 'Netzplan',
      reportTwo: 'report two',
      login: 'Login',
      logout: 'Abmelden',
      trainTypes: 'Baureihen',
      trainTypeReferences: 'Baureihen-Referenzen',
      board: 'ZIM Dammtor',
      users: 'Benutzer',
      tools: 'Werkzeuge',
      graphics: 'Rohdatenplotter',
      firmwareVersions: 'Firmware-Versionen',
      hardwareVersions: 'Hardware-Versionen',
      validation: 'Validierung',
      companies: 'Verkehrsunternehmen (EVU)',
      configuration: 'Konfiguration',
      administration: 'Administration',
      stations: 'Stationen',
      applicationConfiguration: 'Systemkonfiguration',
      monitoring: 'Monitoring',
      monitoringAlerts: 'Monitoring-Protokoll',
      serverStatus: 'Server-Status',
      subscribers: 'Subscribers',
      sites: 'Installationsorte',
      departures: 'Abfahrt (RIS)'
    },
    footer: { help: 'Hilfe' },
    formActionRow: {
      saving: 'Speichern...',
      save: 'Speichern',
      backTo: 'Ergebnisliste',
      cancel: 'Abbrechen'
    },
    toast: { successSaved: 'Gespeichert' },
    signalR: {
      disconnected: 'SignalR Verbindungsversuch...',
      connected: 'SignalR verbunden'
    },
    loader: { dataIsLoading: 'Daten werden geladen...' },
    noData: { thereIsNoData: 'Es liegen keine Daten vor.' },
    roles: {
      CompanyAdministrator: 'Administrator im Verkehrsunternehmen',
      GlobalAdministrator: 'Administrator global',
      User: 'Benutzer'
    },
    startEndDatesFilters: { startDate: 'Von', endDate: 'Bis' },
    monitoringAlertsTypes: {
      DeviceVoltageInternalIsLow: 'Die interne Spannung ist zu niedrig',
      DeviceVoltageExternalIsLow: 'Die externe Spannung ist zu niedrig',
      DeviceHasMissedMeasurements: 'Fahrplan Zug verpasst',
      DeviceHasInvalidMeasurements: 'Ungultige Messung',
      AnalysisServiceIsNotAvailable: 'Analysis-service nicht verfügbar',
      ImpossibleWagonsCount: 'Unzulässige Wagon Anzahl',
      DeviceDoesNotSendData: 'Geräte sendet keine Daten',
      ReceiverServiceIsNotAvailable: 'Receiver-service nicht verfügbar',
      ImpossibleFirmware: 'Ungultige Firmware',
      DeviceHasNoSite: 'Kein Installationsort angegeben',
      DeviceSendDuplicatedDate: 'Doppeltes Sensordatum',
      NewFirmware: 'Neue Firmware'
    },
    powerSupplyTypes: {
        Solar: 'Solar',
        Stationary12v: 'stationär 12v',
        BatteryOnly: 'Akku',
        Stationary230v: 'stationär 230v'
    }
  },
  siteAddPage: { title: 'Installationsort hinzufügen' },
  siteEditPage: { title: 'Installationsort bearbeiten' },
  siteEditor: {
    fromStation: 'Von Station',
    toStations: 'Nach Stationen',
    track: 'Gleis',
    type: 'Typ',
    trainTypes: 'Baureihen',
    company: 'Verkehrsunternehmen (EVU)',
    latitude: 'Breitengrad',
    longitude: 'Länge',
    description: 'Beschreibung',
    isOperatingBreakEnabled: 'Betriebspause',
    operatingBreaks: 'Betriebspause',
    startDate: 'Von',
    endDate: 'Bis',
    useStaticOperatingBreak: 'Statische Betriebspause',
    staticOperatingBreakStartTime: 'Start (z.B.: 03:00:00)',
    staticOperatingBreakDuration: 'Dauer (in Minuten)',
    defaultTrainTypeId: 'Default Baureihe'
  },
  sitesPage: {
    title: 'Installationsort',
    table: {
      fromStation: 'Von Station',
      toStations: 'Nach Station',
      track: 'Gleis',
      trainTypes: 'Baureihen',
      company: 'Verkehrsunternehmen (EVU)',
      description: 'Beschreibung',
      isOperatingBreakEnabled: 'Betriebspause',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      deleteConfirm: 'Löschen?'
    },
    add: 'Installationsort hinzufügen',
    cannotDeleteSiteIsInUse: 'Kann nicht gelöscht werden, weil der Installationsort in Verwendung ist.'
  },
  siutAddPage: { title: 'Neues SIUT-Gerät' },
  siutDetailsPage: { title: 'SIUT-Gerät' },
  siutEditPage: { title: 'SIUT-Gerät' },
  siutEditor: { endpoint: 'Webservice URI', company: 'Verkehrsunternehmen' },
  siutsPage: {
    title: 'SIUT-Geräte',
    table: {
      endpoint: 'Webservice URI',
      company: 'Verkehrsunternehmen',
      edit: 'Bearbeiten'
    },
    add: 'Hinzufügen'
  },
  stationAddPage: { title: 'Station hinzufügen' },
  stationEditPage: { title: 'Station bearbeiten' },
  stationEditor: {
    title: 'Name',
    latitude: 'Breitengrad',
    longitude: 'Länge',
    externalId: 'Externe Id'
  },
  stationsPage: {
    title: 'Stationen',
    table: {
      title: 'Name',
      latitude: 'Breitengrad',
      longitude: 'Länge',
      externalId: 'Externe Id',
      edit: 'Bearbeiten'
    },
    add: 'Station hinzufügen'
  },
  subscriberAddPage: { title: 'Subscriber hinzufügen' },
  subscriberEditPage: { title: 'Subscriber bearbeiten' },
  subscriberEditor: {
    endpoint: 'Webservice URI',
    description: 'Beschreibung',
    isDeactivated: 'Deaktiviert',
    company: 'Verkehrsunternehmen'
  },
  subscribersPage: {
    title: 'Subscribers',
    table: {
      endpoint: 'Webservice URI',
      description: 'Beschreibung',
      isDeactivated: 'Deaktiviert',
      company: 'Verkehrsunternehmen',
      edit: 'Bearbeiten'
    },
    add: 'Subscriber hinzufügen'
  },
  trainTypeAddPage: { title: 'Baureihen hinzufügen' },
  trainTypeEditPage: { title: 'Baureihen bearbeiten' },
  trainTypeEditor: {
    title: 'Name',
    emptyWagonTrancparencyThreshold:
      'Opazität Leerzug (0.0 - 1.0; Richtwert: 0.45)',
    fullWagonTrancparencyThreshold:
      'Opazität voll besetzter Zug (0.0 - 1.0; Richtwert: 0.92)',
    factor: 'untrennbare Wagen-Bestandteile',
    factorRepetition: 'Faktor Wagenwiederholung',
    totalCapacity: 'Passagierkapazität',
    wagonSpecification: 'Wagonspezifikation z. B.: 0.3-0.5;0.4-0.6'

  },
  trainTypeReferenceAddPage: { title: 'Baureihen-Referenz hinzufügen' },
  trainTypeReferenceEditPage: { title: 'Baureihen-Referenz bearbeiten' },
  trainTypeReferenceEditor: {
    trainType: 'Baureihen',
    rawMeasurement: 'Messung Rohdaten', 
    description: 'Beschreibung',  
    percentageOfFullness: 'Auslastung (in Prozent)', 
    isForTest: 'Datensatz für Test benutzten',  
  },
  trainTypeReferencesPage: {
    title: 'Baureihen-Referenzen',
    table: {
      trainType: 'Baureihen',
      rawMeasurement: 'Messung Rohdaten',      
      lastModificationDate: 'Datum letzte Bearbeitung', 
      lastModificationUser: 'Letzter Bearbeiter', 
      description: 'Beschreibung',  
      percentageOfFullness: 'Auslastung (in Prozent)', 
      toggleGraphic: 'Grafik',  
      edit: 'Bearbeiten',
      delete: 'Löschen',
      deleteConfirm: 'Löschen?'
    },
    add: 'Baureihen-Referenz hinzufügen'
  },
  trainTypesPage: {
    title: 'Baureihen',
    table: {
      title: 'Name',
      emptyWagonTrancparencyThreshold:
        'Opazität Leerzug (0.0 - 1.0; Richtwert: 0.45)',
      fullWagonTrancparencyThreshold:
        'Opazität voll besetzter Zug (0.0 - 1.0; Richtwert: 0.92)',
      factor: 'untrennbare Wagen-Bestandteile',
      factorRepetition: 'Faktor Wagenwiederholung',
      totalCapacity: 'Passagierkapazität',
      wagonSpecification: 'Wagonspezifikation',
      edit: 'Bearbeiten'
    },
    add: 'Baureihen hinzufügen'
  },
  userAddPage: { title: 'Benutzer hinzufügen' },
  userEditPage: { title: 'Benutzer bearbeiten' },
  userEditor: {
    email: 'Email',
    username: 'Benutzername',
    firstName: 'Vorname',
    lastName: 'Nachname',
    password: 'Kennwort',
    company: 'Verkehrsunternehmen',
    roles: 'Rolle(n)',
    isDeactivated: 'deaktiviert',
    validationErrors: {
      emailExists: 'Diese Email wird bereits verwendet',
      usernameExists: 'Dieser Benutzername wird bereits verwendet',
      companyIdIsRequired: 'Verkehrsunternehmen muss angegeben werden',
      roleNamesAreRequired: 'Rolle muss angegebenn werden',
      roleIsNotAvailable:
        'Nötige Zugriffsrechte für diese Rolle nicht vorhanden'
    }
  },
  userResetPasswordPage: {
    title: 'Kennwort zurücksetzen',
    newPassword: 'Neues Kennwort',
    confirmPassword: 'Passwort bestätigen'
  },
  usersPage: {
    title: 'Benutzer',
    table: {
      name: 'Name',
      username: 'Benutzername',
      email: 'Email',
      roles: 'Rolle(n)',
      company: 'Verkehrsunternehmen',
      edit: 'Bearbeiten',
      resetPassword: 'Kennwort zurücksetzen'
    },
    add: 'Hinzufügen'
  },
  validationTestDetailsPage: {
    title: 'Auswertung der Validierung',
    testRecord: 'Validierungsdaten',
    trainInfo: 'Kondor-Messungen',
    date: 'Datum',
    station: 'Station',
    trainType: 'Baureihe',
    diff: 'Zeitabstand'
  },
  validationTestsPage: {
    title: 'Validierung Baureihen-Erkennung',
    upload: 'Validierungsdaten hochladen',
    uploading: 'Lade hoch...',
    chooseFile: 'Datei auswählen',
    downloadTemplate: 'Download Dateivorlage für Validierungsdaten',
    table: {
      name: 'Name',
      createdDate: 'Datum (Hochladen)',
      download: 'Herunterladen',
      delete: 'Löschen',
      run: 'Start'
    },
    deleteConfirm: 'Löschen?'
  }
});