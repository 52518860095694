import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group';
 


const FirmwareVersionEditor = ({ item: firmwareVersion = {}, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(firmwareVersion.id);
    const [name, setName] = useState(firmwareVersion.name) 
    const [hash, setHash] = useState(firmwareVersion.hash);
    const [description, setDescription] = useState(firmwareVersion.description);
 
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({ id, name, hash, description }); 
    }
  
    return (      
        <div className="firmware-version-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">  
                        <FormGroup 
                            type="text"
                            label="firmwareVersionEditor.name"
                            name="name"
                            value={name}
                            onChange={setName} 
                        />
                        
                        <FormGroup 
                            type="text"
                            label="firmwareVersionEditor.hash"
                            name="hash"
                            value={hash}
                            onChange={setHash} 
                        />

                        <FormGroup 
                            type="text"
                            label="firmwareVersionEditor.description"
                            name="description"
                            value={description}
                            onChange={setDescription} 
                        />
 
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/firmware-versions/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default FirmwareVersionEditor; 