import { registerLang } from 'react-easy-i18n';

registerLang('en', {
  applicationConfigurationEditor: {
    emailServiceSubtitle: 'Email service',
    emailServiceSmtpServer: 'Server',
    emailServiceSmtpPort: 'Port',
    emailServiceSmtpUsername: 'Username',
    emailServiceSmtpPassword: 'Password',
    emailServiceSmtpUseSsl: 'Use SSL',
    monitoringSubtitle: 'Monitoring',
    monitoringNotificationRecipients: 'Notification recipients',
    monitoringMissedMeasurementsThreshold: 'Missed measurements threshold (%)',
    monitoringInvalidMeasurementsThreshold:
      'Invalid measurements threshold (%)',
    validationSubtitle: 'Validation',
    validationFilesFolderPath: 'FilesFolderPath',
    endpointsSubtitle: 'Endpoints',
    endpointsReceiverServices: 'Receiver services',
    endpointsTrafficServices: 'Traffic services',
    endpointsSuitService: 'Suit service',
    endpointsAnalysisService: 'Analysis service',
    globalSubtitle: 'Global',
    globalCompanyInfo: 'Company info'
  },
  applicationConfigurationPage: { title: 'Application Configuration' },
  companiesPage: {
    title: 'Companies',
    table: { title: 'Title', edit: 'Edit' },
    add: 'Add new company'
  },
  companyAddPage: { title: 'Add new company' },
  companyEditPage: { title: 'Edit company' },
  companyEditor: { title: 'Title', logo: 'Logo' },
  dashboardPage: {
    activeAlerts: 'Active alerts',
    title: 'Dashboard',
    status: 'Status',
    lastDataReceivedDate: 'Last data received date',
    track: 'Track',
    noAlerts: 'No alerts',
    noDevice: 'No device',
    weatherForecast: 'Weather forecast',
    sunPower: 'Sun power'
  },
  departuresPage: {
    title: 'Departures',
    table: { date: 'Date', line: 'Line' },
    filters: { site: 'Site', startDate: 'Start date', endDate: 'End date' }
  },
  deviceAddPage: { title: 'Add Device' },
  deviceDetailsPage: { title: 'Device Details' },
  deviceEditPage: { title: 'Edit Device' },
  deviceEditor: {
    serialNumber: 'Serial Number',
    siut: 'Siut',
    hardwareVersion: 'Hardware Version',
    firmwareVersion: 'Firmware Version',
    isOnMonitoring: 'Is on monitoring',
    monitoringVoltageInternalThreshold: 'Monitoring voltage-internal threshold',
    monitoringVoltageExternalThreshold: 'Monitoring voltage-external threshold',
    company: 'Company',
    site: 'Site',
    powerSupplyType: 'Power supply type'
  },
  deviceLogsPage: {
    title: 'Device Messages',
    table: { createdDate: 'Time', message: 'Message' }
  },
  deviceIpAddressesPage: {
    title: 'Device IP addresses',
    table: {
        ipAddress: 'IP Address',
        startDate: 'Start date',
        endDate: 'End Date',
        count: 'Count'
    }
  },
  deviceReport1Page: {
    title: 'Device Report 1',
    download: 'Download as CSV',
    table: { dateTime: 'Time', avg: 'Avg', max: 'Max' }
  },
  deviceTelemetriesPage: {
    title: 'Device Telemetries',
    download: 'Download as CSV',
    table: {
      dateTime: 'Time (Server)',
      deviceDateTime: 'Time (Device)',
      voltageInternal: 'Internal voltage (V)',
      voltageExternal: 'External voltage (V)',
      chargeCurrent: 'Charge current (mA)',
      transmissionSpeed: 'Transmission speed (Kb/s)',
      ipAddress: 'IP address'
    }
  },
  devicesPage: {
    title: 'Devices',
    table: {
      serialNumber: 'Serial number',  report1: 'Report 1',
      hardwareVersion: 'Hardware Version',
      firmwareVersion: 'Firmware Version',
      company: 'Company',
      site: 'Site',
      setSite: 'Please set the site', 
    },
    add: 'Add New'
  },
  devicePage: {
    title: 'Device',
    tabs: {
      details: 'Details', 
      telemetry: 'Telemetry',
      report1: 'Report',
      logs: 'Device Messages',
      ipAddresses: 'Ip addresses',
      edit: 'Edit', 
      measurements: 'Measurements'
    },
    edit: 'Edit',
    delete: 'Delete',
    deleteConfirm: 'Delete?',
  },  
  deviceDetailsPage: {
    id: 'Id',
    serialNumber: 'Serial Number',
    siut: 'Siut',
    hardwareVersion: 'Hardware Version',
    firmwareVersion: 'Firmware Version',
    isOnMonitoring: 'Is on monitoring',
    monitoringVoltageInternalThreshold: 'Monitoring voltage-internal threshold',
    monitoringVoltageExternalThreshold: 'Monitoring voltage-external threshold',
    company: 'Company',
    site: 'Site',
    powerSupplyType: 'Power supply type'
  }, 
  firmwareVersionAddPage: { title: 'Add Firmware Version' },
  firmwareVersionEditPage: { title: 'Edit Firmware Version' },
  firmwareVersionEditor: {
    name: 'Name',
    hash: 'Hash',
    description: 'Description'
  },
  firmwareVersionsPage: {
    title: 'Firmware Versions',
    table: {
      name: 'Name',
      description: 'Description',
      hash: 'Hash',
      edit: 'Edit',
      delete: 'Delete',
      deleteConfirm: 'Are you sure?',
      confirm: 'Confirm',
      confirmConfirm: 'Are you sure?'
    },
    add: 'Add'
  },
  fullnessConfigurationPage: {
    title: 'Fullness Configuration',
    staticLowerThreshold: 'Static Lower Threshold',
    staticUpperThreshold: 'Static Upper Threshold',
    dynamicLowerThreshold: 'Dynamic Lower Threshold',
    dynamicUpperThreshold: 'Dynamic Upper Threshold',
    isDynamicCalculationEnabled: 'Is Dynamic Calculation Enabled',
    explanation1:
      'If all values are <strong>equal or less</strong>  than the <strong>Dynamic Lower Threshold</strong>, then the biggest value is orange, other are green. If all values are equal, then all wagons are green.<br/>Example: <br/>Dynamic Lower Threshold = 0.3 =>',
    explanation2:
      'Else if all values are <strong>equal or more</strong> than the <strong>Dynamic Upper Threshold</strong>, then the biggest value is red, other are orange. If all values are equal, then all wagons are red. <br/>  Example: <br/>Dynamic Lower Threshold = 0.7 =>',
    explanation3:
      ' Otherwise, values <strong>less</strong> than the <strong>Static Lower Threshold</strong> are green, values <strong>more</strong> than the <strong>Static Upper Threshold</strong> are red, and other values are orange. <br/>Example: <br/>Static Lower Threshold = 0.65,  Static Upper Threshold = 0.8 =>'
  },
  hardwareVersionAddPage: { title: 'Add Hardware Version' },
  hardwareVersionEditPage: { title: 'Edit Hardware Version' },
  hardwareVersionEditor: { name: 'Name', description: 'Description' },
  hardwareVersionsPage: {
    title: 'Hardware Versions',
    table: { name: 'Name', description: 'Description', edit: 'Edit' },
    add: 'Add'
  },
  historyPage: {
    title: 'History',
    table: {
      dateTime: 'Date Time',
      deviceDateTime: 'Time (Device)',
      fullness: 'Fullness',
      rawData: 'Raw Data',
      expand: 'Expand',
      trainType: 'Train Type',
      line: 'Line',
      comment: 'Comment'
    },
    filters: {
      pleaseChooseSite: 'Please choose a site...',
      pleaseChooseDevice: 'Please choose a device...',
      pleaseChooseDataSources: 'Please choose data sources...',
      transparencyArduino: 'Transparency Arduino',
      transparencyAnalysisService: 'Transparency Analysis Service',
      transparencyAnalysisServiceLive: 'Transparency Analysis Service Live',
      graffiti: 'Graffiti',
      transparencyCSharpLive: 'Transparency C# Live',
      noOptions: 'No options',
      startDate: 'Start Date',
      endDate: 'End Date',
      includeInvalidRecords: 'Include invalid records',
      onlyWithUserComments: 'Only with user comments',
      onlyWithSystemComments: 'Only with system comments',
      moreFilters: 'More filters...',
      close: 'Close',
      dataSources: 'Data sources',
      date: 'Date'
    },
    graphic: { wagons: 'Wagons', fullness: 'Fullness' },
    commentModal: { title: 'Comment', comment: 'Comment' },
    downloadTrainInfos: 'Download',
    addComment: 'Add comment',
    editComment: 'Edit comment',
    copyRawData: 'Copy raw data',
    copied: 'Copied',
    wasDeleted: 'This item was deleted',
    delete: 'Delete',
    deleteConfirm: 'Delete?',
  },
  loginPage: {
    loginText: 'Please sign in',
    username: 'Username',
    password: 'Password',
    login: 'Login',
    loggining: 'Logining...',
    messages: { invalidUsernameOrPassword: 'Invalid username or password' }
  },
  logsPage: {
    title: 'Logs',
    pleaseChooseDate: 'Please choose a date',
    dateTime: 'DateTime',
    level: 'Level',
    application: 'App',
    requestId: 'RequestId',
    message: 'Message'
  },
  monitoringAlertsPage: {
    title: 'Monitoring alerts',
    table: {
      type: 'Type',
      device: 'Device',
      date: 'Date',
      startDate: 'Start date',
      endDate: 'End date',
      voltageExternal: 'Voltage external',
      voltageInternal: 'Voltage internal',
      expectedMeasurementsCount: 'Expected measurements count',
      receivedMeasurementsCount: 'Received measurements count',
      missedMeasurementsCount: 'Missed measurements count',
      invalidMeasurementsCount: 'Invalid measurements count',
      solvedDate: 'Solved date',
      comment: 'Comment',
      description: 'Description',
      solved: 'Solved',
      addComment: 'Add comment',
      showMoreComments: 'Show more comments',
      commentsLeft: 'comments left',
      showAllComments: 'Show all comments'
    },
    filters: {
      onlyActive: 'Only active',
      pleaseChooseDevice: 'Please choose a device...',
      pleaseChooseMonitoringAlertType:
        'Please choose a monitoring alert type...'
    },
    commentModal: {
      deactivateTitle: 'Deactivate monitoring alert',
      addCommentTitle: 'Add new comment',
      comment: 'Comment',
      deactivateSameAlerts: 'Deactivate alerts with the same type'
    },
    monitoringIsRunning: 'Monitoring is running...',
    runMonitoring: 'Run monitoring',
    monitoringRunSuccess: 'Monitoring run successefully',
    confirmDeactivateAll: 'Are you sure?',
    deactivateAll: 'Deactivate all', 
    deactivateAllByDeviceId: 'Deactivate all for the selected device', 
    deactivateAllSuccess: ' monitoring alerts were deactivated',
  },
  monitoringPage: {
    title: 'Monitoring',
    isAnalysisServiceAvailable: 'Is analysis service available',
    table: {
      device: 'Device',
      lastDataReceivedDate: 'Last data received date',
      batteryLevel: 'Battery level',
      voltage: 'Voltage for the last data received date (V)',
      voltageExternal: 'External',
      voltageInternal: 'Internal',
      measurements: 'Measurements',
      expectedMeasurements: 'Expected',
      receivedMeasurements: 'Received',
      missedMeasurements: 'Missed',
      invalidMeasurements: 'Invalid'
    }
  },
  notAuthorizedPage: {
    title: 'Error 401: not authorized',
    description: 'Sorry, but you are not authorized to access this page.'
  },
  report1Page: { title: 'Visualisation Map' },
  recalculationPage: {
    title: "Recalculation",
    traintypeOption: "recalculate TrainTypes",
    transparencyOption: "recalculate Transparency",
    occupancyOption: "recalculate Occupancy",
    from: "StartDate",
    to: "EndDate",
    recalculate: "Recalculate",
    description: ""
  },
  serverStatusPage: { title: 'Server status' },
  shared: {
    mainMenu: {
      dashboard: 'Dashboard',
      recalculate: 'Recalculation',
      history: 'History',
      fullnessConfigurations: 'Fullness Configurations',
      devices: 'Devices',
      kondorDevices: 'Kondor-devices',
      siuts: 'Siuts',
      logs: 'Logs',
      reports: 'Reports',
      reportOne: 'Report one',
      reportTwo: 'Report two',
      login: 'Login',
      logout: 'Logout',
      trainTypes: 'Train Types',
      trainTypeReferences: 'Train Type References',
      board: 'ZIM Dammtor',
      users: 'Users',
      tools: 'Tools',
      graphics: 'Graphics',
      firmwareVersions: 'Firmware Versions',
      hardwareVersions: 'Hardware Versions',
      validation: 'Validation',
      companies: 'Companies',
      configuration: 'Configuration',
      administration: 'Administration',
      stations: 'Stations',
      applicationConfiguration: 'Application configuration',
      monitoring: 'Monitoring',
      monitoringAlerts: 'Monitoring alerts',
      serverStatus: 'Server status',
      subscribers: 'Subscribers',
      sites: 'Sites',
      departures: 'Departures (RIS)'
    },
    footer: { help: 'Help' },
    formActionRow: {
      saving: 'Saving...',
      save: 'Save',
      backTo: 'back to list',
      cancel: 'Cancel'
    },
    toast: { successSaved: 'Saved!' },
    signalR: {
      disconnected: 'SignalR reconnecting...',
      connected: 'SignalR is connected'
    },
    loader: { dataIsLoading: 'Please wait, data is loading...' },
    noData: { thereIsNoData: 'There is no data.' },
    roles: {
      CompanyAdministrator: 'Company Administrator',
      GlobalAdministrator: 'Global Administrator',
      User: 'User'
    },
    startEndDatesFilters: { startDate: 'Start', endDate: 'End' },
    monitoringAlertsTypes: {
      DeviceVoltageInternalIsLow: 'Voltage internal is low',
      DeviceVoltageExternalIsLow: 'Voltage external is low',
      DeviceHasMissedMeasurements: 'Has missed measurements',
      DeviceHasInvalidMeasurements: 'Has invalid measurements',
      AnalysisServiceIsNotAvailable: 'Analysis service is not available',
      ImpossibleWagonsCount: 'Impossible wagons count',
      DeviceDoesNotSendData: 'Device does not send data',
      ReceiverServiceIsNotAvailable: 'Receiver service is not available',
      NewFirmware: 'New firmware',
      DeviceHasNoSite: 'Device has no site',
      DeviceSendDuplicatedDate: 'Device send duplicated date'
    }, 
    powerSupplyTypes: {
        Solar: 'Solar',
        Stationary12v: 'Stationary 12v',
        BatteryOnly: 'Battery Only',
        Stationary230v: 'Stationary 230v'
    }
  },
  siteAddPage: { title: 'Add site' },
  siteEditPage: { title: 'Edit site' },
  siteEditor: {
    fromStation: 'From station',
    toStations: 'To station',
    track: 'Track',
    type: 'Type',
    trainTypes: 'Train types',
    company: 'Company',
    latitude: 'Latitude',
    longitude: 'Longitude',
    description: 'Description',
    isOperatingBreakEnabled: 'Is operating break enabled',
    operatingBreaks: 'Operating breaks',
    startDate: 'Start date',
    endDate: 'End date',
    useStaticOperatingBreak: 'Use static operating break',
    staticOperatingBreakStartTime: 'Start time (for example: 03:00:00)',
    staticOperatingBreakDuration: 'Duration (in minutes)',
    defaultTrainTypeId: 'Default train type'
  },
  sitesPage: {
    title: 'Sites',
    table: {
      fromStation: 'From station',
      toStations: 'To station',
      track: 'Track',
      trainTypes: 'Train types',
      company: 'Company',
      description: 'Description',
      isOperatingBreakEnabled: 'Is operating break enabled',
      edit: 'Edit',
      delete: 'Delete',
      deleteConfirm: 'Delete?'
    },
    add: 'Add new site', 
    cannotDeleteSiteIsInUse: 'Can not delete the site, because it is in use'
  },
  siutAddPage: { title: 'Add Siut' },
  siutDetailsPage: { title: 'Siut Details' },
  siutEditPage: { title: 'Edit Siut' },
  siutEditor: { endpoint: 'Endpoint', company: 'Company' },
  siutsPage: {
    title: 'Siuts',
    table: { endpoint: 'Endpoint', company: 'Company', edit: 'Edit' },
    add: 'Add new'
  },
  stationAddPage: { title: 'Add new station' },
  stationEditPage: { title: 'Edit station' },
  stationEditor: {
    title: 'Title',
    latitude: 'Latitude',
    longitude: 'Longitude',
    externalId: 'External Id'
  },
  stationsPage: {
    title: 'Stations',
    table: {
      title: 'Title',
      latitude: 'Latitude',
      longitude: 'Longitude',
      externalId: 'External Id',
      edit: 'Edit'
    },
    add: 'Add new station'
  },
  subscriberAddPage: { title: 'Add Subscriber' },
  subscriberEditPage: { title: 'Edit Subscriber' },
  subscriberEditor: {
    endpoint: 'Endpoint',
    description: 'Description',
    isDeactivated: 'Is deactivated',
    company: 'Company'
  },
  subscribersPage: {
    title: 'Subscribers',
    table: {
      endpoint: 'Endpoint',
      description: 'Description',
      isDeactivated: 'Is deactivated',
      company: 'Company',
      edit: 'Edit'
    },
    add: 'Add new'
  },
  trainTypeAddPage: { title: 'Add Train Type' },
  trainTypeEditPage: { title: 'Edit Train Type' },
  trainTypeEditor: {
    title: 'Title',
    emptyWagonTrancparencyThreshold: 'Empty wagon transparency threshold',
    fullWagonTrancparencyThreshold: 'Full wagon transparency threshold',
    factor: 'Factor',
    factorRepetition: 'Factor repetition',
    totalCapacity: 'Total passenger capacity',
    wagonSpecification: 'Detailed wagon transparency threshold eg. 0.3-0.5;0.4-0.6'
  },
  trainTypeReferenceAddPage: { title: 'Add Train Type References' },
  trainTypeReferenceEditPage: { title: 'Edit Train Type References' },
  trainTypeReferenceEditor: {
    trainType: 'Train Type',
    rawMeasurement: ' Raw measurement data',    
    description: 'Description',  
    percentageOfFullness: 'Percentage of fullness',  
    isForTest: 'Is for test',  
  },
  trainTypeReferencesPage: {
    title: 'Train Type References',
    table: {
      trainType: 'Train Type',
      rawMeasurement: 'Raw measurement',      
      lastModificationDate: 'Modified date',
      lastModificationUser: 'Modified user',
      description: 'Description',
      percentageOfFullness: 'Fullness %',      
      toggleGraphic: 'Graphic',
      isForTest: 'Is for test',
      edit: 'Edit',
      delete: 'Delete',
      deleteConfirm: 'Delete?'
    },
    add: 'Add train type reference'
  },
  trainTypesPage: {
    title: 'Train Types',
    table: {
      title: 'Title',
      emptyWagonTrancparencyThreshold: 'Empty wagon transparency threshold',
      fullWagonTrancparencyThreshold: 'Full wagon transparency threshold',
      wagonSpecification: 'Detailed wagon transparency threshold',
      factor: 'Factor',
      factorRepetition: 'Factor repetition',
      totalCapacity: 'Total passenger capacity',
      edit: 'Edit',
    },
    add: 'Add train type'
  },
  userAddPage: { title: 'Add User' },
  userEditPage: { title: 'Edit User' },
  userEditor: {
    email: 'Email',
    username: 'Username',
    firstName: 'First name',
    lastName: 'Last name',
    password: 'Password',
    company: 'Company',
    roles: 'Roles',    
    isDeactivated: 'Is deactivated',
    validationErrors: {
      emailExists: 'Email exists',
      usernameExists: 'Username exists',
      companyIdIsRequired: 'Company is required',
      roleNamesAreRequired: 'Role is required',
      roleIsNotAvailable: 'Role is not available'
    }
  },
  userResetPasswordPage: {
    title: 'Reset user password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password'
  },
  usersPage: {
    title: 'Users',
    table: {
      name: 'Name',
      username: 'Username',
      roles: 'Roles',
      email: 'Email',
      company: 'Company',
      edit: 'Edit',
      resetPassword: 'Reset password',
    },
    add: 'Add'
  },
  validationTestDetailsPage: {
    title: 'Validation for train types',
    testRecord: 'Validation records',
    trainInfo: 'Kondor records',
    date: 'Date',
    station: 'Station',
    trainType: 'Train type',
    diff: 'Diff'
  },
  validationTestsPage: {
    title: 'Validation Tests',
    upload: 'Upload',
    uploading: 'Uploading...',
    chooseFile: 'Choose a file...',
    downloadTemplate: 'Download template',
    table: {
      name: 'Name',
      createdDate: 'Created date',
      download: 'Download',
      delete: 'Delete',
      run: 'Run'
    },
    deleteConfirm: 'Delete?'
  }
});
