import React from 'react'; 
import { withRouter } from 'react-router-dom';
import queryString from 'query-string' 

import HistoryPageView from './history-page-view'; 
import Configuration from '../../core/configuration'; 
import SignalRConnector from '../../components/shared/signalr-connector';

import { DevicesService, SitesService, getTrainInfos, downloadTrainInfos, deleteTrainInfo } from '../../services/'; 
 
import moment from 'moment'; 
import translate from '../../core/translate';

class HistoryPage extends React.Component {  
    _isMounted = false; 
    _devicesService = new DevicesService();
    _sitesService = new SitesService();

    state = {   
        isDevicesDataLoaded: false,
        isTrainInfoDataLoaded: false,
        
        devices: [],
        sites: [],
        
        filters: {
            id: null,
            selectedSiteId: '',
            selectedDeviceId: '',
            startDate: null,
            endDate: null, 
            includeInvalidRecords: false,
            dataSources: [],
            onlyWithUserComments: false,
            onlyWithSystemComments: false,
            selectedPage: 1,
        },
         
        trainInfos: null,
        itemsPerPageCount: null, 
        pagesCount: null,
        itemsCount: null,
 
        signalRHubConnectionState: null
    }

    async componentDidMount() { 
        this._isMounted = true;
          
        const devices = await this._devicesService.getItems(); 
        const sites = await this._sitesService.getItems(); 

        const { 
            id,
            selectedSiteId, 
            selectedDeviceId,
            startDate, 
            endDate, 
            includeInvalidRecords, 
            dataSources, 
            onlyWithUserComments,
            onlyWithSystemComments,
            selectedPage = 1
        } = queryString.parse(this.props.location.search);
        
        const filters =  {
            id,
            selectedSiteId, 
            selectedDeviceId,
            startDate: (startDate && moment(startDate, "DD.MM.YYYY HH:mm").toDate()) || null,
            endDate: (endDate && moment(endDate, "DD.MM.YYYY HH:mm").toDate()) || null,
            includeInvalidRecords: includeInvalidRecords || false,
            dataSources: (dataSources && dataSources.split(',')) || [],
            onlyWithUserComments: onlyWithUserComments || false,
            onlyWithSystemComments: onlyWithSystemComments || false,
            selectedPage: parseInt(selectedPage),
        };
        
        this.setState({ 
            devices,
            sites,
            isDevicesDataLoaded: true,
            filters
        });
            
        if (selectedSiteId || selectedDeviceId || id){  
            await this.getTrainInfoData(filters);  
        }
    }
     
    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    onFiltersChange = async (filters, shouldFetch) => {
        if (shouldFetch) {
            filters.selectedPage = 1;

            await this.setState({  
                trainInfos: [],
                selectedPage: 1,
                isTrainInfoDataLoaded: false,
                filters: filters  
            });

            await this.getTrainInfoData(filters);   
        }   
        else{
            filters.selectedPage = this.state.filters.selectedPage;

            await this.setState({   
                filters: filters  
            });
        }
        
        this.updateQueryString(filters);
    } 
 
    updateQueryString = (filters) => {
        const queryStringParams = [];
        for (let [key, value] of Object.entries(filters)) {
            if (value){ 
                if(key === 'startDate' || key === 'endDate'){  
                    queryStringParams.push(`${key}=${moment(value).format('DD.MM.YYYY HH:mm')}`);
                } 
                else if(key === 'dataSources' && value.length > 0){   
                    queryStringParams.push(`${key}=${value.join(',')}`);
                }
                else{
                    queryStringParams.push(`${key}=${value}`);
                }
            }
        }
        window.history.pushState({}, '', `/history/?${queryStringParams.join('&')}`);
    }

    getTrainInfoData = async (filters) => {
        let { id, selectedSiteId, selectedDeviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments, selectedPage } = filters;
        
        if (selectedSiteId || selectedDeviceId || id) {
            startDate = startDate ? moment(startDate).format('DD.MM.YYYY HH:mm') : null; 
            endDate = endDate ? moment(endDate).format('DD.MM.YYYY HH:mm') : null; 
    
            const response = await getTrainInfos(id, selectedSiteId, selectedDeviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments, selectedPage || 1);  
    
            console.log(response);

            this.setState({
                pagesCount: response.pagesCount,
                trainInfos: response.trainInfos,
                itemsCount: response.itemsCount,
                isTrainInfoDataLoaded: true
            });
        } 
        else{
            this.setState({
                pagesCount: 0,
                trainInfos: null,
                itemsCount: 0,
                isTrainInfoDataLoaded: true
            });
        }
    }
  
    onPageChange = async (page) => {
        const filters = {...this.state.filters, selectedPage: page };
        this.setState({filters});

        this.updateQueryString(filters);
        await this.getTrainInfoData(filters); 
    }
   
    onDownloadTrainInfos = async () => {
        let { selectedSiteId, selectedDeviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments } = this.state.filters;
        startDate = startDate ? moment(startDate).format('DD.MM.YYYY HH:mm') : null; 
        endDate = endDate ? moment(endDate).format('DD.MM.YYYY HH:mm') : null; 
  
        await downloadTrainInfos(selectedSiteId, selectedDeviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments);
    }
    
    onConnectionStateChange = (connectionState) => {
        this.setState({
            signalRHubConnectionState: connectionState
        });
    }

    onReceiveTrainInfoInternal = async(trainInfo) => { 
        console.log('receiveTrainInternalInfo', trainInfo);
        const { startDate, endDate, includeInvalidRecords } = this.state.filters;
        const trainInfoDate = moment(trainInfo.createdDate).toDate();
 
        const startDateFilter = ((startDate === null) || (startDate && startDate <= trainInfoDate));
        const endDateFilter = ((endDate === null) || (endDate && endDate >= trainInfoDate));
        const includeInvalidRecordsFilter = (includeInvalidRecords || trainInfo.isValidByAnalysisService);
        const shouldAppendTrainInfo = startDateFilter && endDateFilter && includeInvalidRecordsFilter;

        if (shouldAppendTrainInfo) { 
            this.setState(prevState => {
                const doesTrainInfoExist = prevState.trainInfos.some(item => item.id === trainInfo.id);
                if (!doesTrainInfoExist){
                    return ({
                        trainInfos: [trainInfo, ...prevState.trainInfos]
                    });
                }                
            });
        }
    }

    onDelete = async (trainInfoId) => {
        if (window.confirm(translate('historyPage.deleteConfirm'))){
            await deleteTrainInfo(trainInfoId);
            this.setState(prevState => { 
                return ({
                    trainInfos: prevState.trainInfos.filter((item) => item.id !== trainInfoId)
                });           
            });
        } 
    }
  
    render() {
        const { selectedSiteId, selectedDeviceId } = this.state.filters;
        const signalRRooms = [];
        if (selectedSiteId){
            signalRRooms.push(`TrainInfo_Site_${selectedSiteId}`);
        }
        if (selectedDeviceId){
            signalRRooms.push(`TrainInfo_Device_${selectedDeviceId}`);
        }

        return (
            <>
                {
                    signalRRooms.length > 0 && 
                    <SignalRConnector 
                        hubUrl={Configuration.traffic.trainInfoHub}
                        eventListeners={[{ name: 'ReceiveTrainInfoInternal', action: this.onReceiveTrainInfoInternal }]}
                        rooms={signalRRooms}
                        onConnectionStateChange={this.onConnectionStateChange}
                        />
                }

                <HistoryPageView 
                    {...this.state}                                         
                    onDownloadTrainInfos={this.onDownloadTrainInfos}                    
                    onPageChange={this.onPageChange}                                     
                    onFiltersChange={this.onFiltersChange}
                    onDelete={this.onDelete}
                    />
            </> 
        );
    }
}

export default withRouter(HistoryPage);