import React, { useEffect, useState } from 'react'; 

import DeviceEditPage from '../device-edit-page'; 
import DeviceDetailsPage from '../device-details-page';
import DeviceLogsPage from '../device-logs-page'; 
import DeviceTelemetriesPage from '../device-telemetries-page'; 
import DeviceReport1Page from '../device-report-1-page'; 
import DeviceIpAddressesPage from '../device-ip-addresses-page';  

import PrivateRoute from '../../../components/shared/private-route'; 
import Translater from '../../../components/shared/translater';
import PageTitleRow from '../../../components/shared/page-title-row';
import Loader from '../../../components/shared/loader';
import Error from '../../../components/shared/error'; 
import Tabs from '../components/tabs';
import Breadcrumbs from '../../../components/shared/breadcrumbs';

import { DevicesService } from '../../../services';
import { Link, Redirect } from 'react-router-dom';
import translate from '../../../core/translate';

const DeviceLayoutPage = (props) => {    
    const [isLoaded, setIsLoaded] = useState(false);
    const [device, setDevice] = useState(null); 
    const [error, setError] = useState(null);
    const [breadcrumbsItems, setBreadcrumbsItems] = useState([{ to: '/devices/', title: <Translater text="shared.mainMenu.kondorDevices" /> }]);
    const [redirectTo, setRedirectTo] = useState(null);

    const _devicesService = new DevicesService(); 

    useEffect(() => {  
        getDevice();
    }, []);

    const getDevice = async () => {
        const deviceId = props.match.params.id;

        try 
        {
            const response = await _devicesService.getItem(deviceId); 
            setDevice(response); 
            setBreadcrumbsItems([{ to: '/devices/', title: <Translater text="shared.mainMenu.kondorDevices" /> }, { title: response.serialNumber, isActive: true }]);
        }    
        catch(ex)
        {
            setError(ex);
        }
        finally{
            setIsLoaded(true);
        }
    }
  
    const updateDevice = (device) => { 
        setDevice(device);
        setBreadcrumbsItems([{ to: '/devices/', title: <Translater text="shared.mainMenu.kondorDevices" /> }, { title: device.serialNumber, isActive: true }]);
    }

    const deleteDevice = (e) => { 
        e.preventDefault();

        const confirmMessage = translate('devicePage.deleteConfirm');
        if(window.confirm(confirmMessage)){
            _devicesService.deleteItem(device.id).then(() => {
                setRedirectTo('/devices/');
            });  
        } 
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />
    }

    return (
        <div className="device-page-layout">
            <Breadcrumbs items={breadcrumbsItems} />

            {
                isLoaded && !error && 
                <>
                    <PageTitleRow title="devicePage.title" >                        
                        <h1 className="float-left">&#160;{device.serialNumber}</h1>

                        <div className="float-md-right pt-2">
                            <div className="float-left">
                                <Link to={`/devices/${device.id}/edit/`} className="btn btn btn-outline-primary">
                                    <i className="fas fa-pencil-alt mr-2"></i>
                                    <Translater text="devicePage.edit"/>
                                </Link> 

                                <button className="btn btn btn-outline-danger ml-2" onClick={deleteDevice}>
                                    <i className="fas fa-trash mr-2"></i>
                                    <Translater text="devicePage.delete"/>
                                </button> 
                            </div> 
                        </div>
                    </PageTitleRow>

                    <Tabs /> 

                    <div className="px-3 py-3">
                        <PrivateRoute exact path='/devices/:id/' component={() => <DeviceDetailsPage device={device} />} />
                        <PrivateRoute exact path='/devices/:id/edit/' component={() => <DeviceEditPage updateDevice={updateDevice} />} /> 
                        <PrivateRoute exact path='/devices/:id/logs/' component={() => <DeviceLogsPage />} /> 
                        <PrivateRoute exact path='/devices/:id/report1/' component={() => <DeviceReport1Page />} /> 
                        <PrivateRoute exact path='/devices/:id/telemetries/' component={() => <DeviceTelemetriesPage />} /> 
                        <PrivateRoute exact path='/devices/:id/ip-addresses/' component={() => <DeviceIpAddressesPage />} />  
                    </div>                    
                </>
            } 

            {
                isLoaded && error && 
                <Error />
            }

            {
                !isLoaded &&
                <Loader />
            }
        </div>
    );
}

export default DeviceLayoutPage;