import React from 'react';
import Moment from 'react-moment'; 
import moment from 'moment';
import { Link } from 'react-router-dom'; 

import Translater from '../../../../components/shared/translater';
import translate from '../../../../core/translate';
import TrainFullness from '../train-fullness';
import CommentModal from '../comment-modal';
import TrainType from '../train-type';
import Graphic from '../graphic';
import GraffitiInfestation from '../graffiti-infestation';

import './history-item.scss';
import AccessBoundary from '../../../../components/shared/access-boundary';



const HistoryItemView = (props) => {
    const { 
        id, rawMeasurement, 
        createdDate, deviceDate,
        transparencyArduino, 
        transparencyAnalysisService,         
        fullnessAnalysisService, fullnessLevelsAnalysisService, 
        transparencyAnalysisServiceLive, 
        transparencyCSharpLive,
        trainTypeTitle,     
        trainTypeTitleAnalysisServiceLive,

        lineTypeTitle,
        originStationTitle, directionStationTitle,
        fromStationTitle, toStationTitle, toStationArrivalTime,

        isValidByAnalysisService,
        comment,       
        graffitiInfestation,
        trainNumber,

        dataSources,             
        
        isSelected,
        onToggleIsSelected,

        onCopyToClipboard,

        isCommenModalVisible,
        onComment, 
        onCommentModalHide,
        journeyId,

        deviceSerialNumber,
        deviceId,

        isDeleted,
        onDelete
    } = props;
    
    return (        
        <div className="history-item">
            {
                isCommenModalVisible && 
                <CommentModal onCommentModalHide={onCommentModalHide} comment={comment} trainInfoId={id} />
            }

            <div className={`row py-4 row-train-info ${isSelected && 'row-selected'} ${!isValidByAnalysisService && 'row-is-not-valid'}`} key={id}>
                <div className="col-md-auto mb-4 mb-md-0 col-date"> 
                    <span title={`${translate('historyPage.table.deviceDateTime')}: ${deviceDate ? moment(deviceDate).format("DD.MM.YYYY HH:mm:ss") : 'n/a'}`}>
                        <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{createdDate}</Moment>
                    </span>
                    
                    {
                        isSelected &&
                        <div className="text-secondary mt-2">
                            Train Info Id: <Link to={`/history/?id=${id}`} target="_blank">{id}</Link>
                        </div>
                    }
                </div>
                <div className="col-md-auto mb-4 mb-md-0 col-wagons">                        
                    {
                        fullnessLevelsAnalysisService &&  
                        <TrainFullness 
                            values={fullnessAnalysisService} 
                            fullnessLevels={fullnessLevelsAnalysisService} 
                            dataSourceType="stored" 
                            hint={ translate('historyPage.table.stored') } /> 
                    } 
                    {
                        transparencyAnalysisService && dataSources.indexOf('transparencyAnalysisService') > -1 &&
                        <TrainFullness 
                            values={transparencyAnalysisService}                             
                            dataSourceType="transparencyAnalysisService" 
                            hint={ translate('historyPage.table.transparencyAnalysisService') } /> 
                    }
                    {
                        transparencyAnalysisServiceLive && dataSources.indexOf('transparencyAnalysisServiceLive') > -1 &&
                        <TrainFullness 
                            values={transparencyAnalysisServiceLive}                             
                            dataSourceType="transparencyAnalysisServiceLive" 
                            hint={ translate('historyPage.table.transparencyAnalysisServiceLive') } /> 
                    }
                    {
                        transparencyCSharpLive && dataSources.indexOf('transparencyCSharpLive') > -1 &&
                        <TrainFullness 
                            values={transparencyCSharpLive}                             
                            dataSourceType="transparencyCSharpLive" 
                            hint={ translate('historyPage.table.transparencyCSharpLive') } /> 
                    }
                    {
                        transparencyArduino && dataSources.indexOf('transparencyArduino') > -1 &&
                        <TrainFullness 
                            values={transparencyArduino}  
                            dataSourceType="transparencyArduino" 
                            hint={ translate('historyPage.table.transparencyArduino') } />
                    }
                    {
                        graffitiInfestation && dataSources.indexOf('graffiti') > -1 && 
                        <GraffitiInfestation values={graffitiInfestation} />
                    } 
                </div>
                <div className="col-md-auto mb-4 mb-md-0 col-train-type">
                    {
                        trainTypeTitle && 
                        <TrainType value={trainTypeTitle} dataSourceType="stored" />                        
                    }    
                    {
                        trainTypeTitleAnalysisServiceLive && dataSources.indexOf('transparencyAnalysisServiceLive') > -1 &&
                        <TrainType value={trainTypeTitleAnalysisServiceLive} dataSourceType="transparencyAnalysisServiceLive" />   
                    }  
                    {
                        trainNumber &&
                        <span className="badge badge-light">
                            <i className="fas fa-subway fa-fw mr-3"></i> 
                            { trainNumber }
                        </span> 
                    }  
                </div>
                <div className="col-md-auto mb-3 mb-md-0 col-line">
                    {
                        lineTypeTitle &&
                        <>
                            <div className="badge badge-light mr-2 float-left">
                                {lineTypeTitle || '???'}
                            </div>
                            <div className="d-inline-block float-left">
                                {originStationTitle}
                                &#160;&rarr;&#160; 
                                {directionStationTitle}
                                {
                                    isSelected &&                                 
                                    <div className="text-secondary">
                                        {fromStationTitle} 
                                        &#160;&rarr;&#160;
                                        {toStationTitle}
                                        {
                                            toStationArrivalTime &&
                                            <span>
                                                (<Moment format="HH:mm">{toStationArrivalTime}</Moment>)
                                            </span>
                                        }                                         
                                        <div className="mt-2">
                                            JourneyId: { journeyId }
                                        </div>
                                    </div>
                                }
                            </div>
                        </>                        
                    }   
                    {
                        isSelected &&                                 
                        <div className="text-secondary">
                            Device:&#160;
                            <Link to={`/devices/${deviceId}/telemetries/`}>
                                {deviceSerialNumber}
                            </Link>
                        </div>
                    }
                </div>
                <div className="col mb-3 mb-md-0 col-comment">
                    {
                        isDeleted === true &&
                        <div className="text-danger mb-2">
                            <i className="fas fa-info-circle mr-2"></i>                            
                            <Translater text="historyPage.wasDeleted"/> 
                        </div>  
                    }  
                    {
                        comment && comment.map(({ createdDate, createdUserId, createdUserName, text}) => {
                                    return (
                                        <div key={createdDate} className="mb-3">
                                            <div>{ text } </div>
                                            <small className="text-secondary">
                                                { createdUserName } at <Moment format="DD.MM.YYYY HH:mm">{ createdDate }</Moment>
                                            </small>
                                        </div>
                                    )
                                })   
                    }
                    { 
                        <div className="">      
                            {
                                isDeleted === false &&                       
                                <button onClick={onComment} className="btn btn-sm btn-outline-primary mr-2">
                                    <i className="fas fa-pencil-alt mr-2"></i>                                  
                                    <Translater text="historyPage.addComment"/>                                 
                                </button>  
                            }
                            {
                                rawMeasurement &&
                                <button onClick={onCopyToClipboard} className="btn btn-sm btn-outline-primary mr-2" >
                                    <i className="far fa-copy mr-2"></i> 
                                    <span className="state-copy">
                                        <Translater text="historyPage.copyRawData"/>
                                    </span>
                                    <span className="state-copied">
                                        <Translater text="historyPage.copied"/>
                                    </span>
                                </button>
                            }    
                            
                            {
                                isDeleted === false &&
                                <AccessBoundary roles={['GlobalAdministrator']} content={() => {
                                    return ( 
                                        <button onClick={onDelete} className="btn btn-sm btn-outline-danger mr-2">
                                            <i className="fas fa-trash mr-2"></i>                                  
                                            <Translater text="historyPage.delete"/>                                 
                                        </button>                                    
                                    )                                
                                }} />  
                            }                   
                        </div>
                    } 
                </div> 

                {
                    isSelected && 
                    <div className="col-12 pt-3 plot-container">
                        <Graphic rawMeasurement={rawMeasurement} />
                    </div>
                }
                    
                <div className="expand pt-4 px-4 pt-md-1 px-md-2 text-primary" onClick={onToggleIsSelected}> 
                    {
                        isSelected && 
                        <i className="fas fa-chevron-circle-up"></i>
                    }
                    {
                        !isSelected && 
                        <i className="fas fa-chevron-circle-down"></i>
                    }                     
                </div>
            </div>
        </div> 
    );
}

export default HistoryItemView;