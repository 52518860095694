import Configuration from '../core/configuration';
import { getJsonData, sendJsonData, downloadFile } from '../core/web-client';



export const getTrainInfos = async (trainInfoId, siteId, deviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments, page) => {
    const baseUrl = Configuration.traffic.trainInfos;
    const url = `${baseUrl}?id=${trainInfoId || ''}&siteId=${siteId || ''}&deviceId=${deviceId || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}&includeInvalidRecords=${includeInvalidRecords}&onlyWithUserComments=${onlyWithUserComments}&onlyWithSystemComments=${onlyWithSystemComments}&page=${page}`;
 
    const result = await getJsonData(url); 
    return result;
}

export const deleteTrainInfo= async (id) => {
    const baseUrl = Configuration.traffic.trainInfos;
    const url = `${baseUrl}${id}`;

    const result = await sendJsonData(url, null, 'DELETE');    
    return result;
}

export const getAvgAndMaxFullnesses = async (deviceId, startDate, endDate) => {
    const baseUrl = Configuration.traffic.trainInfosAvgAndMax;
    const url = `${baseUrl}?deviceId=${deviceId}&startDate=${startDate || ''}&endDate=${endDate || ''}`;

    const result = await getJsonData(url);    
    return result;
}

export const getFullnessLevels = async (data) => {
    const baseUrl = Configuration.traffic.fullnessLevels;    
    const url = `${baseUrl}?transparency=${data}`;

    const result = await getJsonData(url);    
    return result;
}
 
export const updateComment = async (trainInfoId, data) => {
    const baseUrl = Configuration.traffic.updateTrainComment; 
    const url = `${baseUrl}/${trainInfoId}`;

    const result = await sendJsonData(url, data, 'PUT'); 
    return result;
}

export const downloadTrainInfos = async (siteId, deviceId, startDate, endDate, includeInvalidRecords, onlyWithUserComments, onlyWithSystemComments) => {
    const baseUrl = Configuration.traffic.downloadTrainInfos; 
    const url = `${baseUrl}?siteId=${siteId || ''}&deviceId=${deviceId || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}&includeInvalidRecords=${includeInvalidRecords}&onlyWithUserComments=${onlyWithUserComments}&onlyWithSystemComments=${onlyWithSystemComments}`;

    const result = await downloadFile(url); 

    let objectUrl = window.URL.createObjectURL(result.fileBlob);
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    anchor.href = objectUrl;
    anchor.download = result.fileName;
    anchor.click(); 
    window.URL.revokeObjectURL(objectUrl); 
}